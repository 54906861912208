import { LitElement, html, css, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

@customElement('video-background')
export class VideoBackground extends LitElement {
  @property({ type: Boolean })
  sticky = false

  @property()
  mp4 = ''

  @property()
  webm = ''

  @property()
  poster = ''

  @property({ type: Boolean, reflect: true })
  viewing = false;

  observeScroll() {
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(({ intersectionRatio }) => {
        this.viewing = intersectionRatio > 0
      });
    }, { rootMargin: "20px 0px -20px 0px" });

    observer.observe(this.parentElement as Element);
  }

  static styles = css`
    :host {
      z-index: -5;
      isolation: isolate;
      width: 100vw;
      height: 100vh;
    }

    :host, video {
      display: block;
      object-fit: cover;
      min-height: 880px;
      position: absolute;
      top: 0;
      left: 0;
    }

    video {
      width: 100%;
      height: 100%;
    }

    :host([sticky][viewing]) {
      position: fixed;
    }
  `

  render() {
    this.observeScroll()

    return html`
      <video playsinline autoplay muted loop poster="${ifDefined(this.poster)}">
        <source src="${ifDefined(this.webm)}" type="video/webm">
        <source src="${ifDefined(this.mp4)}" type="video/mp4">
      </video>
    `
  }
}
